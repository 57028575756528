import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FaCaretDown, FaChevronDown, FaChevronRight } from "react-icons/fa";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import styles from "./style.module.scss";
import logo from "../../../components/images/logo.png";
import { Link } from "react-router-dom";

const Header = () => {
  const [collapseMenu, setCollapseMenu] = useState(false);
  const [renderMenu, setRenderMenu] = useState(false);
  const { width } = useWindowDimensions();
  const scrollWidth = width > 991;

  const [show, setShow] = useState(false);

  const [showDropdown, setShowDropdown] = useState(false);
  const [showIcon, setShowIcon] = useState(false);

  const handleClose = () => {
    setShow(false);
    setRenderMenu(false);
  };
  const handleCloseStatic = () => {
    setShow(false);
    setShowDropdown(false);
  };

  useEffect(() => {
    setCollapseMenu(false);
  }, [scrollWidth]);

  useEffect(() => {
    setRenderMenu(false);
  }, []);

  useEffect(() => {
    setShowDropdown(false);
  }, []);

  const clickHandler = (name) => {
    setRenderMenu(!renderMenu);
  };

  const hoverHandler = (name) => {
    setRenderMenu(true);
  };

  const NavBarData = [
    {
      link: "/",
      label: "Home",
      disabled: false,
      subMenuType: "none",
    },
    {
      link: "/services",
      label: "Services",
      disabled: false,
      dropdown: false,
      subMenuType: "none",
    },
    {
      link: "/pricing",
      label: "Pricing",
      disabled: false,
      subMenuType: "none",
    },
    {
      link: "/about-us",
      label: "About Us",
      disabled: false,
      subMenuType: "none",
    },
  ];

  return (
    <>
      <header id="myHeader" className={`${styles.header}`}>
        <Navbar collapseOnSelect expand="lg" className={`${styles.navbar}`}>
          <Container className={`${styles.container}`}>
            <Navbar.Brand href="/" className={`${styles.navbar_brand}`}>
              <img alt="logo" src={logo} className={`${styles.logo}`} />
            </Navbar.Brand>
            <Navbar.Toggle
              onClick={() => {
                setCollapseMenu(true);
                setShow(true);
              }}
              aria-controls="responsive-navbar-nav offcanvasNavbar"
              className={`${styles.navbar_toggle}`}
            />
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className={`${styles.navbar_Collapse}`}>
              <Nav className={`me-auto ${styles.nav}`}>
                <Link className={`header-border-animation `} to="/">
                  Home
                </Link>

                <Link className={`header-border-animation `} to="/services">
                  Services
                </Link>
                <Link className={`header-border-animation `} to="/pricing">
                  Pricing
                </Link>
                <Link className={`header-border-animation `} to="/aboutUs">
                  About Us
                </Link>
                {/* <Link className={`header-border-animation `} to="/portfolio">
                  Portfolio
                </Link>
                <Link className={`header-border-animation `} to="/pages">
                  Pages
                </Link> */}
                <Link className={`header-border-animation `} to="/contactUs">
                  Contact Us
                </Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
};

export default Header;
