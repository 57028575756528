import React from "react";
import { Row, Col, Button, Container } from "react-bootstrap";
import flower from "../../images/hero-img.svg";

import styles from "./style.module.scss";

const ServicesMain = () => {
  return (
    <>
      <section className={`${styles.ServicesMain}`}>
        <div className={`background`}>
          <div className={`backgroundLeft ${styles.backgroundLeft}`} />
          <Container className={`${styles.container}`}>
            <Row className={`${styles.row}`}>
              <Col lg={6} md={12} className={`${styles.column_right}`}>
                <p className={`${styles.car}`}> Car Rental Services</p>
                <p className={`${styles.renting}`}>
                  Purchase, Sales, Rent A car
                </p>
                <p className={`${styles.Services}`}>
                  Partner with 18+ Car Rental-Savvies to establish a
                  permissionless and tamper-proof business without trading off
                  your data integrity and sancitity.{" "}
                </p>
                {/* <p className={`${styles.description}`}>
                  As the world's biggest online car rental service, we
                  specialise in finding the cheapest car retal deals from major
                  cities such as Islamabad, Rawalpindi, Lahore, and Budget.
                  Dailv, weekly, or monthly car rentals... just fill in our
                  search from to compare deals from different car category- and
                  find out how much you can save when you rent a car from us
                </p> */}
              </Col>
              <Col
                lg={6}
                md={12}
                className={`mt-sm-5 mt-md-0 d-flex justify-content-center align-items-center ${styles.img_col}`}>
                <img src={flower} alt="flower" className={`${styles.flower}`} />
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default ServicesMain;
