import React from "react";
import { Row, Col } from "react-bootstrap";
import styles from "./style.module.scss";

const AboutUsMain = () => {
  return (
    <>
      <section className={`${styles.AboutUsMain}`}>
        <div className={`${styles.container}`}>
          <Row className={`${styles.row}`}>
            <Col sm={12} className={`${styles.column_left}`}>
              <h3 className={`heading ${styles.heading}`}>
                WE ARE CHANGING HOW CARS ARE BOUGHT & SOLD
              </h3>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default AboutUsMain;
