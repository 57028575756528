import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import styles from "./style.module.scss";

const AboutCars = () => {
  return (
    <>
      <section className={`${styles.AboutCars}`}>
        <Container className={` ${styles.container}`}>
          <Row className={`${styles.row}`}>
            <Col lg={6} md={12} className={`${styles.colLeft}`}>
              <h2 className={`headingOne ${styles.headingOne}`}>
                {" "}
                Available Cars
              </h2>
              <p>
                Cars are one of the biggest financial purchases in your life. In
                your lifetime, you'll spend over five years in one. Yet all too
                often, from search to the sale, buyers and sellers dread the
                process.
              </p>
              <p>
                From your first car to your fifteenth, we want to make buying
                and selling a car something you can look forward to. We’re
                taking a data driven and user centric approach to create new and
                better ways to buy and sell cars.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AboutCars;
