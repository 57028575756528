import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import styles from "./style.module.scss";
import car1 from "../../images/cars/car5.jpg";
import car2 from "../../images/cars/imgg3.JPG";
import car3 from "../../images/cars/imgg3.JPG";
import car4 from "../../images/cars/imgg3.JPG";
import car5 from "../../images/cars/civic/civic.jpg";
import car6 from "../../images/cars/car1.jpg";

const items = [
  {
    title: "Suzuki Wagon R",
    perDay: "4,500 Rs.",
    perMonth: "75,000 Rs.",
    car: car1,
  },
  {
    title: "Toyota Yaris Gli M/T",
    perDay: "6,000 Rs.",
    perMonth: "120,000 Rs.",
    car: car2,
  },
  {
    title: "Toyota Yaris Gli A/T",
    perDay: "7,000 Rs.",
    perMonth: "90000 Rs.",
    car: car3,
  },
  {
    title: "Toyota Yaris Ativ X",
    perDay: "5000 Rs.",
    perMonth: "90000 Rs.",
    car: car4,
  },
  {
    title: "Honda Civic 1.8 Oriel",
    perDay: "7000 Rs.",
    perMonth: "150000 Rs.",
    car: car5,
  },
  {
    title: "Toyota Corolla Gli A/T",
    perDay: "5000 Rs.",
    perMonth: "100000 Rs.",
    car: car6,
  },
];

const PopularCategories = () => {
  return (
    <>
      <section className={`${styles.PopularCategories}`}>
        <div className={`background`}>
          <div className={`backgroundRight ${styles.backgroundRight} `} />
          <Container className={`${styles.container}`}>
            <h2 className={`headingOne`}> Popular Categories</h2>
            <Row className={`${styles.row}`}>
              {items.map((item) => (
                <Col lg={4} sm={12} className={`${styles.colLeft}`}>
                  <Card className={`${styles.card}`}>
                    <Card.Img
                      variant="top"
                      src={item.car}
                      className={`${styles.img}`}
                    />
                    <Card.Body className={`${styles.cardBody}`}>
                      <Card.Title className={`${styles.title}`}>
                        {item.title}
                      </Card.Title>
                      <Card.Text className={`${styles.text}`}>
                        {item.text}
                      </Card.Text>
                      <div className={`buttonWrap`}>
                        <Button
                          className={`button ${styles.button}`}
                          variant="none"
                          type="submit">
                          More
                        </Button>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default PopularCategories;
