import React, { useEffect } from "react";
import { Row, Col, Button, Container } from "react-bootstrap";
import { Pagination, Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";

import styles from "./style.module.scss";
import civic from "../../images/cars/civic/civic8.jpg";
import civic1 from "../../images/cars/civic/civic8.jpg";
import civic2 from "../../images/cars/civic/civic8.jpg";
import civic3 from "../../images/cars/civic/civic8.jpg";
import civic4 from "../../images/cars/civic/civic8.jpg";
import civic5 from "../../images/cars/civic/civic8.jpg";
import civic6 from "../../images/cars/civic/civic8.jpg";
import civic7 from "../../images/cars/civic/civic8.jpg";
import civic8 from "../../images/cars/civic/civic8.jpg";
// import civic9 from "../../images/cars/civic/civic9.jpg";
// import civic10 from "../../images/cars/civic/civic10.jpg";
// import civic11 from "../../images/cars/civic/civic11.jpg";

const PricingMain = () => {
  const items = [
    {
      img1: civic,
      title: "title 1",
      subTitle: "subtitle 1",
      subDescription: "subdescription",
      description: "description",
    },
    {
      img1: civic,
      title: "title 1",
      subTitle: "subtitle 1",
      subDescription: "subdescription",
      description: "description",
    },
    {
      img1: civic,
      title: "title 1",
      subTitle: "subtitle 1",
      subDescription: "subdescription",
      description: "description",
    },
    {
      img1: civic,
      title: "title 1",
      subTitle: "subtitle 1",
      subDescription: "subdescription",
      description: "description",
    },
    {
      img1: civic,
      title: "title 1",
      subTitle: "subtitle 1",
      subDescription: "subdescription",
      description: "description",
    },
    {
      img1: civic,
      title: "title 1",
      subTitle: "subtitle 1",
      subDescription: "subdescription",
      description: "description",
    },
    {
      img1: civic,
      title: "title 1",
      subTitle: "subtitle 1",
      subDescription: "subdescription",
      description: "description",
    },
    {
      img1: civic,
      title: "title 1",
      subTitle: "subtitle 1",
      subDescription: "subdescription",
      description: "description",
    },
  ];
  //
  const settings = {
    infinite: false,
    autoplay: false,
    autoplaySpeed: 6500,
    prevArrow: <GrFormPrevious />,
    nextArrow: <GrFormNext />,
    // lazyLoad: true,
    // dots: false,
    // focusOnSelect: true,
    swipeToSlide: true,
    speed: 1200,
    // slidesToShow: 3.5,
    // slidesToScroll: 3.5,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 350,
        settings: {
          speed: 2500,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          speed: 2500,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className={`${styles.pricingMain}`}>
        <div className={`container-fluid ${styles.container}`}>
          <h2 className={`${styles.headingOne}`}>Get to know Car details</h2>
          <Row className={`${styles.row}`}>
            <Col sm={3} md={3} lg={3} xl={3} className={`${styles.colLeft}`}>
              <Swiper
                // slidesPerView={3.5}
                spaceBetween={20}
                navigation={true}
                modules={[Navigation]}
                {...settings}
              >
                {items.map((item) => (
                  <SwiperSlide className={`swiper-slide ${styles.swiperSlide}`}>
                    <Col sm={3} md={3} lg={3} xl={3} className={`${styles.colText}`}>
                      <img className={`${styles.img}`} src={item.img1} alt="img" />
                      <div className={`${styles.colWrap}`}>
                        <p className={`${styles.title}`}>{item.title}</p>
                        <h4 className={`${styles.subTitle}`}>{item.subTitle}</h4>
                      </div>
                    </Col>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default PricingMain;
