import React from "react";
import { Row, Col, Button, Container, Form } from "react-bootstrap";
import { BsFillTelephoneFill } from "react-icons/bs";
import { HiOutlineMail, HiOutlineLocationMarker } from "react-icons/hi";
import styles from "./style.module.scss";

const Contactus = () => {
  return (
    <>
      <section className={`${styles.Contactus}`}>
        <div className={`background`}>
          <div className={`backgroundRight ${styles.backgroundRight} `} />
          <div className={`backgroundLeft ${styles.backgroundLeft}`} />
          <Container className={`${styles.container}`}>
            <h1 className={`headingOne`}>Contact Us</h1>
            <Row className={styles.row}>
              {/* Form  */}
              <Col xs={12} lg={6} className={`${styles.form_col}`}>
                <Form className={`text-left ${styles.form}`}>
                  <Row>
                    <Form.Group
                      className={`mb-4 px-4 ${styles.formGroup}`}
                      controlId="formPlaintextEmail">
                      <Form.Label className={`${styles.formLabel}`}>
                        Name
                      </Form.Label>
                      <Form.Control
                        className={`${styles.formControlText}`}
                        type="text"
                        name="name"
                        placeholder="Full Name"
                      />
                    </Form.Group>
                    <Form.Group
                      className={`mb-4 px-4 ${styles.formGroup}`}
                      controlId="exampleForm.ControlInput1">
                      <Form.Label
                        className={`fs-6 fw-bold ${styles.formLabel}`}>
                        Email
                      </Form.Label>
                      <Form.Control
                        className={`${styles.formControlText}`}
                        type="email"
                        name="email"
                        placeholder="Email@example.com"
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-4 px-4"
                      controlId="exampleForm.ControlTextarea1">
                      <Form.Label
                        className={`fs-6 fw-bold ${styles.formLabel}`}>
                        Description
                      </Form.Label>
                      <Form.Control
                        placeholder="Description"
                        className={`${styles.formControlText} ${styles.description}`}
                        as="textarea"
                        name="desc"
                        rows={6}
                      />
                    </Form.Group>
                    <div className={`text-center mt-3 px-4 buttonWrap`}>
                      <Button
                        variant="none"
                        className={`lh-base fw-bold button ${styles.submitBtn}`}
                        type="submit"
                        style={{ opacity: 1 }}>
                        Submit
                      </Button>
                    </div>
                  </Row>
                </Form>
              </Col>
              {/* Social Links  */}

              <Col xs={12} lg={5} className={`${styles.social}`}>
                <h4 className={`${styles.TESTIMONIALS}`}>TESTIMONIALS</h4>
                <h2 className={`${styles.get}`}>
                  Get In <span className={`${styles.touch}`}>Touch </span> .
                </h2>
                <p className={`${styles.text}`}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vestibulum facilisis quam eget ex blandit cursus. Proin est
                  magna, semper a. Donec eros sem, rhoncus eu pellentesque sit
                  amet,{" "}
                </p>
                <Row className={`${styles.link_row}`}>
                  <Col xs={3} lg={1} className={`${styles.linkcol}`}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href=""
                      className={`${styles.link}`}>
                      <HiOutlineLocationMarker className={`${styles.icon}`} />
                    </a>
                  </Col>
                  <Col xs={9} lg={11} className={`${styles.address_col}`}>
                    <p className={`${styles.text}`}>Office Address</p>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href=""
                      className={`${styles.link}`}>
                      address here
                    </a>
                  </Col>
                </Row>
                <Row className={`${styles.link_row}`}>
                  <Col xs={3} lg={1} className={`${styles.linkcol}`}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href=""
                      className={`${styles.link}`}>
                      <BsFillTelephoneFill className={`${styles.icon}`} />
                    </a>
                  </Col>
                  <Col xs={9} lg={11} className={`${styles.address_col}`}>
                    <p className={`${styles.text}`}>Call Us</p>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="tel:0311 5071507"
                      className={`${styles.link}`}>
                      0311 5071507
                    </a>
                  </Col>
                </Row>
                <Row className={`${styles.link_row}`}>
                  <Col xs={3} lg={1} className={`${styles.linkcol}`}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="fkz.d3vil@gmail.com"
                      className={`${styles.link}`}>
                      <HiOutlineMail className={`${styles.icon}`} />
                    </a>
                  </Col>
                  <Col xs={9} lg={11} className={`${styles.address_col}`}>
                    <p className={`${styles.text}`}>Mail Us</p>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="mailto:fkz.d3vil@gmail.com,fatho.bukhari@gmail.com"
                      className={`${styles.link}`}>
                      fkz.d3vil@gmail.com
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default Contactus;
