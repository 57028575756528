import React from "react";
import Layout from "../components/layout";
import Contactus from "../components/contactUs/Contactus";

const ContactUs = () => {
  return (
    <Layout>
      <Contactus />
    </Layout>
  );
};

export default ContactUs;
