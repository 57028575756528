import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import styles from "./style.module.scss";

const CurrentOpenings = () => {
  return (
    <>
      <section className={`${styles.CurrentOpenings}`}>
        <Container className={` ${styles.container}`}>
          <Row className={`${styles.row}`}>
            <Col lg={6} md={12} className={`${styles.colLeft}`}>
              CurrentOpenings
            </Col>
            <Col className={`${styles.colRight}`}></Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default CurrentOpenings;
