import React from "react";
import Footer from "../layout/Footer";
import Header from "../layout/Header";

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <Header />
      <main style={{ height: "100%", width: "100%", background: "black" }}>
        {children}
      </main>
      <Footer />
    </React.Fragment>
  );
};

export default Layout;
