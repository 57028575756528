import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import styles from "./style.module.scss";
import img1 from "../../images/cars/img-10.JPG";
import img2 from "../../images/cars/img-3.JPG";
import img3 from "../../images/cars/img-2.JPG";
import img4 from "../../images/cars/img-6.JPG";
import img5 from "../../images/cars/img-13.JPG";
import img6 from "../../images/cars/img-7.JPG";

//
const items = [
  {
    img: img1,
  },
  {
    img: img2,
  },
  {
    img: img3,
  },
  {
    img: img4,
  },
  {
    img: img5,
  },
  {
    img: img6,
  },
];
const availableCars = () => {
  return (
    <>
      <section className={`${styles.availableCars}`}>
        <div className={`background`}>
          <div className={`backgroundRight ${styles.backgroundRight} `} />
          <div className={`backgroundLeft ${styles.backgroundLeft} `} />
          <Container className={`${styles.container}`}>
            <h1 className={`headingOne`}>Armored Vehicles </h1>
            <Row className={`${styles.row}`}>
              {items.map((item) => (
                <Col lg={4} md={12} className={`${styles.colLeft}`}>
                  <img className={`${styles.img}`} src={item.img} alt="img" />
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default availableCars;
