import React from "react";
import styles from "./style.module.scss";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import greyWagonr from "../../../components/images/cars/car5.jpg";
import car2 from "../../../components/images/cars/imgg3.JPG";
import car3 from "../../../components/images/cars/imgg4.JPG";
import car4 from "../../../components/images/cars/imgg4.JPG";
import car5 from "../../../components/images/cars/civic/civic.jpg";
import car6 from "../../../components/images/cars/car2.jpg";
import car7 from "../../../components/images/cars/TC2.JPG"
import car8 from "../../../components/images/cars/rs.jpg"

const CarPricing = () => {
  //
  const settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2500,
  prevArrow: <GrFormPrevious />,
    nextArrow: <GrFormNext />,
    lazyLoad: true,
    dots: false,
    focusOnSelect: true,
    swipeToSlide: true,
    speed: 2200,
    slidesToShow: 3,
    slidesToScroll: 1,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 350,
        settings: {
          speed: 2500,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          speed: 2500,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  //
  const descpTruncate = (input) =>
    input?.length > 80 ? `${input.substring(0, 80)}...` : input;
  const titleTruncate = (titleInput) =>
    titleInput?.length > 40 ? `${titleInput.substring(0, 40)}...` : titleInput;
  //
  const items = [
    {
      title: "Suzuki Wagon R",
      perDay: "4,500 Rs.",
      perMonth: "75,000 Rs.",
      car: greyWagonr,
    },
    {
      title: "Toyota Yaris Gli M/T",
      perDay: "6,000 Rs.",
      perMonth: "120,000 Rs.",
      car: car2,
    },
    {
      title: "Toyota Yaris Gli A/T",
      perDay: "6,5000 Rs.",
      perMonth: "125,000 Rs.",
      car: car3,
    },
    {
      title: "Toyota Yaris Ativ X",
      perDay: "6,5000 Rs.",
      perMonth: "120,000 Rs.",
      car: car4,
    },
    {
      title: "Honda Civic 1.8 Oriel",
      perDay: "9,000 Rs.",
      perMonth: "180,000 Rs.",
      car: car5,
    },
    {
      title: "Toyota Corolla Gli A/T",
      perDay: "7,000 Rs.",
      perMonth: "125,000 Rs.",
      car: car6,
    },
    {
      title: "Toyota Corolla Altis 1.6",
      perDay: "7,000 Rs.",
      perMonth: "125,000 Rs.",
      car: car7,
    },
    {
      title: "Honda Civic 2023",
      perDay: "15,000 Rs.",
      perMonth: "225,000 Rs.",
      car: car8,
    },
  ];
  return (
    <>
      <section className={`${styles.carPricing}`}>
        <div className={`background`}>
          <div className={`backgroundRight ${styles.backgroundRight} `} />
          <div className={`backgroundLeft ${styles.backgroundLeft}`} />
          <Container className={`${styles.container}`}>
            <h2 className={`headingOne`}>Pricing</h2>
            <div className={`${styles.row}`}>
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                navigation={true}
                modules={[Navigation]}
                {...settings}
                className={`mySwiper ${styles.slider}`}>
                {items?.map((item) => (
                  <SwiperSlide className={`${styles.SwiperSlide}`}>
                    <div className={`${styles.imgWrap}`}>
                      <img
                        className={`${styles.cardImg}`}
                        alt="cars"
                        src={item.car}
                      />
                    </div>
                    <div className={`${styles.column_left}`}>
                      <div className={`${styles.card}`}>
                        <div className={`${styles.textWrap}`}>
                          <h3 className={`${styles.title}`}>
                            <span>Car Name:</span> {item.title}
                          </h3>
                          <Row className={`${styles.row}`}>
                            <Col lg={3} className={`${styles.col}`}>
                              <span className={`${styles.perDay}`}>
                                {" "}
                                <span>Rate Per Day:</span> {item.perDay}
                              </span>
                            </Col>
                            <Col lg={3} className={`${styles.col}`}>
                              <span className={`${styles.perMonth}`}>
                                {" "}
                                <span>Rate Per Month:</span> {item.perMonth}
                              </span>
                            </Col>
                            <Col lg={2} className="px-0">
                              <div className={`buttonWrap`}>
                                <Button
                                  variant="none"
                                  className={`button ${styles.button}`}
                                  type="submit"
                                  style={{ opacity: 1 }}>
                                  Book Now
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>{" "}
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </Container>
        </div>
      </section>
    </>
  );
};

export default CarPricing;
