import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./style.module.scss";

const CitiesServices = ({ CitiesServices }) => {
  //
  const items = [
    {
      title: "ISLAMABAD",
      subTitle: "Travel within islamabad full day or half day ",
    },
    {
      title: "RAWALPINDI",
      subTitle: "Travel within rawalpindi full day or half day",
    },
    {
      title: "LAHORE",
      subTitle: "Travel within lahore full day or half day",
    },
    {
      title: "FAISLABAD",
      subTitle: "Travel within faislabad full day or half day",
    },
    {
      title: "NORTHERN",
      subTitle:
        "Travel from any desired city upto khunjerab Pass or any Northern Area",
    },
    {
      title: "KASHMIR",
      subTitle:
        "Travel from any desired city upto kashmir or any kahsmir Area, Nelum,Shadara,Muree,Muzafarabad,Khel",
    },
  ];
  //
  return (
    <>
      <section className={`${styles.CitiesServices}`}>
        <div className={styles.containerWrapper}>
          <Container className={styles.container}>
            <Row className={styles.row}>
              <Col xs={12} className={styles.col}>
                <h2 className={`${styles.title} `}>
                  Hire Professional Drivers for these Trips
                </h2>
                <p>Lets explore how & where we do the magic:</p>
              </Col>
            </Row>
            <Row className={`g-5 ${styles.cardsRow}`}>
              {items.map((card, id) => (
                <Col lg={6} xl={4} className={styles.cardCol}>
                  <div
                    className={`mx-auto cardWrapperCommon ${
                      CitiesServices
                        ? `CitiesCardWrapper`
                        : `skyRocketCardWrapper${id + 1}`
                    } ${styles.skyRocketCard}`}>
                    <h3>{card?.title}</h3>
                    <hr />
                    <p> {card?.subTitle}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default CitiesServices;
