import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import styles from "./style.module.scss";

const JoinUs = () => {
  return (
    <>
      <section className={`${styles.JoinUs}`}>
        <Container className={`${styles.container}`}>
          <Row className={`${styles.row}`}>
            <Col sm={12} className={`${styles.column_left}`}>
              <h3 className={`heading ${styles.heading}`}>JOIN US</h3>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default JoinUs;
