import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import styles from "./style.module.scss";

const WhoWeAre = () => {
  return (
    <>
      <section className={`${styles.WhoWeAre}`}>
        <Container className={` ${styles.container}`}></Container>
      </section>
    </>
  );
};

export default WhoWeAre;
