import React from "react";
import Layout from "../components/layout";
import { PricingMain, CarPricing } from "../components/pricing";

const Pricing = () => {
  return (
    <Layout>
      <PricingMain />
      <CarPricing />
    </Layout>
  );
};

export default Pricing;
