import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./style.module.scss";
import logo from "../../../components/images/logo.png";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaWhatsapp,
  FaInstagram,
} from "react-icons/fa";
import { Link } from "react-router-dom";

//
const Footer = () => {
  const colOne = [
    {
      icon: logo,
    },
  ];

  return (
    <>
      <footer className={`${styles.footer}`}>
        <div className={`background`}>
          <div className={`backgroundRight ${styles.backgroundRight} `} />
          <Container className={styles.container}>
            <Row className={styles.logo_row}>
              {colOne.map((item) => (
                <Col xs={12} lg={12}>
                  <Link to="/">
                    <img alt="logo" src={item.icon} className={styles.logo} />
                  </Link>
                </Col>
              ))}
            </Row>
            <Row className={styles.link_row}>
              <Col xs={12} lg={12} className={styles.column_link}>
                <ul className={styles.list_wrap}>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>
                    <Link to="/pricing">Pricing</Link>
                  </li>
                  <li>
                    <Link to="/aboutUs">About Us</Link>
                  </li>
                  {/* <li>
                  <Link to="/portfolio">Portfolio</Link>
                </li>
                <li>
                  <Link to="/pages">Pages</Link>
                </li> */}
                  <li>
                    <Link to="/contactUs">Contact Us</Link>
                  </li>
                </ul>
                {/* <p className={styles.text}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                  elit tellus, luctus nec ullamcorper mattis.
                </p> */}
              </Col>
              <Col xs={12} lg={12} className={styles.column_social}>
                <div className={styles.column_wrap}>
                  <a target="_blank" href="">
                    <FaFacebookF className={styles.social_icon} />
                  </a>
                  <a target="_blank" href="">
                    <FaTwitter className={styles.social_icon} />
                  </a>
                  <a target="_blank" href="">
                    <FaLinkedinIn className={styles.social_icon} />
                  </a>
                  <a target="_blank" href="">
                    <FaWhatsapp className={styles.social_icon} />
                  </a>
                  <a target="_blank" href="">
                    <FaInstagram className={styles.social_icon} />
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
          <Container className={styles.copyrights}>
            <hr />
            <Row className={styles.row}>
              <Col className={styles.col}>
                <p className={styles.text}>
                  Copyright ------ All Right Reserved By faiXiworld.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
    </>
  );
};

export default Footer;
