import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Services from "./pages/Services";
import ContactUs from "./pages/ContactUs";
import Pricing from "./pages/Pricing";
import "./style.scss";
import "../src/styles/theme/variables.scss";
//
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "aboutUs",
    element: <AboutUs />,
  },
  {
    path: "services",
    element: <Services />,
  },
  {
    path: "contactUs",
    element: <ContactUs />,
  },
  {
    path: "pricing",
    element: <Pricing />,
  },
]);
//
const App = () => {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
};

export default App;
