import React from "react";
import Layout from "../components/layout";
import HomeMain from "../components/home/homeMain";
import PopularCategories from "../components/home/PopularCategories";
import AvailableCars from "../components/home/AvailableCars";

// import { HomeMain, AvailableCars } from "../components/home";

const Home = ({}) => {
  return (
    <Layout>
      <HomeMain />
      <PopularCategories />
      <AvailableCars />
    </Layout>
  );
};

export default Home;
