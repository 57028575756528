import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import styles from "./style.module.scss";
import { HiOutlineMail } from "react-icons/hi";
import { BsChevronRight } from "react-icons/bs";
//
const Problems = () => {
  const items = [
    {
      title: "The Problem",
      description:
        "Buying and selling cars is a hassle. Autolist is on a mission to make finding, selling, & financing a car easy",
    },
    {
      title: "The Approach",
      description:
        "Autolist is building a better automotive buying experience for everyone. We employ the latest and greatest tech and the largest database of vehicles to power car searches across Android, iOS and the web to provide our users with the best car buying experience anywhere",
    },
    {
      title: "The Results",
      description:
        "While we’ve just started, we serve millions of visitors and facilitate over a million car purchases. We’re profitable and growing fast.",
    },
  ];
  return (
    <>
      <section className={`${styles.Problems}`}>
        <Container className={`${styles.container}`}>
          <Row className={`${styles.row}`}>
            <Col lg={5} md={12} className={`${styles.colLeft}`}>
              {items.map((item) => (
                <div>
                  <h3 className={`${styles.title}`}>{item.title}</h3>
                  <p className={`${styles.description}`}>{item.description}</p>
                </div>
              ))}
            </Col>
            <Col lg={5} md={12} className={`${styles.colRight}`}>
              <Row className={`${styles.dataRow}`}>
                <Col xs={3} sm={3} className={`${styles.col}`}>
                  <HiOutlineMail />
                </Col>
                <Col xs={6} sm={6} className={`${styles.col}`}>
                  <p className="fw-bold">Chat with us</p>
                  <p>Get us in touch & lest us know what you think.</p>
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  className={`${styles.col} ${styles.colLast}`}>
                  <BsChevronRight />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Problems;
