import React from "react";
import Layout from "../components/layout";
import ServicesMain from "../components/services/ServicesMain";
import CitiesServices from "../components/services/CitiesServices";
import RentalServices from "../components/services/RentalServices";

// import  from "../components/services/";
// import  from "../components/services/";
// import  from "../components/services/";

const Services = () => {
  return (
    <Layout>
      <ServicesMain />
      <CitiesServices />
      <RentalServices />
    </Layout>
  );
};

export default Services;
