import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import styles from "./style.module.scss";
import flower from "../../images/banner.jpg";

const homeMain = () => {
  return (
    <>
      <section className={`${styles.homeMain}`}>
        <div className={`background`}>
          <div className={`backgroundLeft ${styles.backgroundLeft}`} />
          <Container className={`text-center ${styles.container}`}>
            <Row className={`${styles.row}`}>
              <Col lg={6} md={12} className={`${styles.col}`}>
                <p className={`${styles.hi}`}> Hi, </p>
                <p className={`${styles.heading}`}> This is Faizan Khan</p>
                <p className={`${styles.title}`}>
                  {" "}
                  Welcome To My Website This Site Delivers The Best Car Rental
                  Experince
                </p>
              </Col>
              <Col
                lg={6}
                md={12}
                className={`mt-sm-5 mt-md-0 d-flex justify-content-center align-items-center ${styles.img_col}`}>
                <img src={flower} alt="flower" className={`${styles.flower}`} />
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default homeMain;
