import React from "react";
import Layout from "../components/layout";
import AboutUsMain from "../components/aboutUs/AboutUsMain";
import AboutCars from "../components/aboutUs/AboutCars";
import Problems from "../components/aboutUs/Problems";
import WhoWeAre from "../components/aboutUs/WhoWeAre";
import JoinUs from "../components/aboutUs/JoinUs";
import JoinUsToo from "../components/aboutUs/JoinUsToo";
import AboutUsSlider from "../components/aboutUs/AboutUsSlider";
import CurrentOpenings from "../components/aboutUs/CurrentOpenings";

const AboutUs = () => {
  return (
    <Layout>
      <AboutUsMain />
      <AboutCars />
      <Problems />
      <WhoWeAre />
      <JoinUs />
      <JoinUsToo />
      <AboutUsSlider />
      <CurrentOpenings />
    </Layout>
  );
};

export default AboutUs;
