import React from "react";
import { Row, Col, Button, Container } from "react-bootstrap";
import flower from "../../images/hero-img.svg";
import readyToMakeBanner from "../../images/img-flower.webp";

import styles from "./style.module.scss";

const RentalServices = () => {
  return (
    <>
      <section className={`${styles.RentalServices}`}>
        <Container className={`ReadyToMake ${styles.container}`}>
          <Row className={`${styles.row}`}>
            <Col md={6} className={`${styles.column_left}`}>
              <p className={`mb-2 ${styles.heading_one}`}>
                As the world's biggest online car rental service, we specialise
                in finding the cheapest car retal deals from major cities such
                as Islamabad, Rawalpindi, Lahore, and Budget. Dailv, weekly, or
                monthly car rentals... just fill in our search from to compare
                deals from different car category- and find out how much you can
                save when you rent a car from us
              </p>
              {/* <a href="/contact-us">
                <Button
                  type="button"
                  variant="none"
                  className={`mt-2 homeButton fw-light ${styles.button} ${styles.button_one}`}>
                  {strapiData?.buttons[0]?.title}
                </Button>
              </a>
              <Button
                target="_blank"
                type="button"
                variant="none"
                onClick={() =>
                  openInNewTab("https://calendly.com/invoblox/book-a-slot?")
                }
                className={`mt-2 homeButton fw-light ${styles.button}`}>
                {strapiData?.buttons[1]?.title}
              </Button> */}
            </Col>
            <Col md={6} className={`${styles.column_right}`}>
              <img
                loading="lazy"
                className={`${styles.readyToMakeBanner}`}
                src={readyToMakeBanner}
                alt={readyToMakeBanner}
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section className={`${styles.ReadyToMakeMobView}`}>
        <div className={`ReadyToMake ${styles.container}`}>
          <Row className={`${styles.row}`}>
            <Col xs={8} sm={6} md={6} className={`${styles.column_left}`}>
              <h2 className={`mb-2 ${styles.heading_one}`}>
                {/* {strapiData?.title} */}
              </h2>
              {/* <div className={`${styles.buttonWrap}`}>
                <a href={strapiData?.buttons[0]?.url}>
                  <Button
                    type="button"
                    variant="none"
                    className={`mt-2 homeButton fw-light ${styles.button} ${styles.button_one}`}>
                    {strapiData?.buttons[0]?.title}
                  </Button>
                </a>
                <a href={strapiData?.buttons[1]?.url}>
                  <Button
                    target="_blank"
                    type="button"
                    variant="none"
                    className={`mt-2 homeButton fw-light ${styles.button}`}>
                    {strapiData?.buttons[1]?.title}
                  </Button>
                </a>
              </div> */}
            </Col>
            <Col xs={4} sm={6} md={6} className={`${styles.column_right}`}>
              <img
                loading="lazy"
                className={`${styles.readyToMakeBannerMobView}`}
                // src={readyToMakeBannerMobView}
                // alt={readyToMakeBannerMobView}
              />
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default RentalServices;
